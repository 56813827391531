@property --btnPrimary {
    syntax: '<color>';
    initial-value: #4679EC;
    inherits: false;
  }

  @property --btnPrimarySecond {
    syntax: '<color>';
    initial-value: #1944A6;
    inherits: false;
}


@property --btnPurchase {
    syntax: '<color>';
    initial-value: #406BCB;
    inherits: false;
  }

  @property --btnPurchaseSecond {
    syntax: '<color>';
    initial-value: #6600C0;
    inherits: false;
}

@property --btnPriceFree {
    syntax: '<color>';
    initial-value: #122638;
    inherits: false;
  }

  @property --btnPriceFreeSecond {
    syntax: '<color>';
    initial-value: #122638;
    inherits: false;
}

@property --btnPricePurchase {
    syntax: '<color>';
    initial-value: #ffffff;
    inherits: false;
  }

  @property --btnPricePurchaseSecond {
    syntax: '<color>';
    initial-value: #ffffff;
    inherits: false;
}


// /**
//  * Buttons
//  */
$btn-font-weight: 600 !default;
$btn-font-size: 16px !default;
$btn-font-size-mobile: 14px !default;

$btn-padding-x: 25px !default;
$btn-padding-y: 10px !default;

$line-height-base: 30px !default;


/* Common button styles */
.btn {
    font-family: $font-family-regular;
    display: inline-block;
    padding: $btn-padding-y $btn-padding-x;
    color: $color-white;
    vertical-align: middle;
    text-align: center;
    white-space: nowrap;
    font-size: $btn-font-size;
    font-weight: $btn-font-weight;
    line-height: $line-height-base;
    letter-spacing: .2px;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
    border-radius: 12px;
    // box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.07);
    outline: 0;
    border: 0 !important;
    background: none;
    position: relative;
    z-index: 1;
    min-width: 140px;

    &:focus {
        outline: none;
    }

    > span {
        vertical-align: middle;
    }

    &:disabled {
        pointer-events: none;
        opacity: .5;
    }

    &:hover {
        text-decoration: none !important;
    }

    @include media-breakpoint-down(sm) {
        font-size: 13px;
    }
}

a.disabled{
    pointer-events: none;
    opacity: .5;
}

.btn-primary {
    padding: 12px $btn-padding-x !important;
}

.btn-primary,
.btn-purchase {
	color: $color-white;
	overflow: hidden;
    padding: 15px $btn-padding-x;

    background: linear-gradient(var(--btnPurchase), var(--btnPurchaseSecond));
    background: -webkit-linear-gradient(#406BCB,#6600C0);
    transition: --btnPurchase 1s, --btnPurchaseSecond 1s;

    letter-spacing: 0.83px;

    &:hover {
        --btnPurchase: #6600C0;
        --btnPurchaseSecond: #406BCB;
        background: -webkit-linear-gradient(#6600C0,#406BCB);

        span {
            color: $color-bg-color-button-dashboard;
        }
    }

    span {
        position: relative;
        top: -4px;
        font-family: $font-family-regular;
        margin-left: 5px;
    }
}

.btn-price-free {
	color: $color-white;
	overflow: hidden;
    padding: 15px $btn-padding-x;

    background: linear-gradient(var(--btnPriceFree), var(--btnPriceFreeSecond));
    background: -webkit-linear-gradient(#122638,#122638);
    transition: --btnPriceFree 1s, --btnPriceFreeSecond 1s;

    &:hover {
        --btnPriceFree: #ffffff;
        --btnPriceFreeSecond: #ffffff;
        color: #030A12;
        background: -webkit-linear-gradient(#ffffff,#ffffff);
    }
}

.btn-price-purchase {
	color: #030A12;
	overflow: hidden;
    padding: 15px $btn-padding-x;

    background: linear-gradient(var(--btnPricePurchase), var(--btnPricePurchaseSecond));
    background: -webkit-linear-gradient(#ffffff,#ffffff);
    transition: --btnPricePurchase 1s, --btnPricePurchaseSecond 1s;

    &:hover {
        --btnPricePurchase: #122638;
        --btnPricePurchaseSecond: #122638;
        color: $color-white;
        background: -webkit-linear-gradient(#122638,#122638);
    }
}