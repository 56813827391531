.s-faq {
    padding: 0 0 50px;

    &__subhead {
        font-family: $font-family-bold;
        font-weight: 600;

        font-size: 18px;
        line-height: 26px;
        margin-bottom: 30px;
        display: block;
        margin-bottom: 10px;

        background: linear-gradient(90.05deg, #4FDBEA -3.05%, #4666CA 32.08%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        text-align: center;
    }

    &__headline {
        font-family: $font-family-headings;
        font-size: 38px;
        line-height: 45px;
        margin-bottom: 15px;
        color: $color-white;
        text-align: center;

        @include media-breakpoint-down(sm) {
            font-size: 22px;
            line-height: 32px;
        }

        span {
            color: $color-primary;
        }
    }

    &__subline {
        font-family: $font-family-medium;
        font-size: 20px;
        line-height: 30px;
        color: #A4B1C1;

        margin: 20px auto;
        text-align: center;

        display: block;
        max-width: 600px;

        @include media-breakpoint-down(sm) {
            font-size: 18px;
            line-height: 22px;
        }
    }
}