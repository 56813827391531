.logo {
	display: inline-block;
	text-decoration: none;

	display: flex;
	align-items: center;

	&__img {
		display: inline-block;
		width: 112px;
		height: 100%;

		@include media-breakpoint-down(sm) {
			width: 80px;
		}
	}

	&:hover {
		text-decoration: none !important;;
	}
}