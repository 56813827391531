.modal {
    margin: 0 15px;

    &__close {
        @include transition($duration: .15s);
      //   background: $color-white !important;
        color: $color-white;

        position: absolute;
        top: 10px;
        right: 10px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        min-width: auto !important;

        font-size: 18px;

        &::before {
          position: relative;
          left: 0px;
        }
    }

    &__container  {
        border-radius: 8px;
        margin: 15px;
        padding: 0 !important;

        color: $color-white;
        position: relative;

        max-width: 600px;

        overflow-y: scroll;
        -ms-overflow-style: none;

        @include media-breakpoint-down(sm) {
          padding: 30px !important;
        }

        .login__header__headline {
            margin-bottom: 15px;
            text-shadow: none;
            font-size: 20px;
            line-height: 26px;

        }

        .btn {
            margin-top: 30px;
        }
    }

    &__content {
      padding: 20px;
      margin: 0 !important;

      @include media-breakpoint-down(sm) {
        grid-template-columns: repeat(1, 1fr);
      }

      label {
        font-family: $font-family-regular;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #A4B1C1;
        display: block;
        letter-spacing: -0.015em;

        margin-bottom: 25px;

        span {
          font-size: 12px;
        }
      }
    }

    .modal__title {
      display: block;
      text-align: center;
      width: 100%;
      margin-top: 40px !important;
    }

    .btn {
      width: 100%;
      margin-top: 10px;
    }
}