.footer {
	&__wrap {
        transition: all ease-out .2s !important;

        position: relative;
        z-index: 9999;
        width: 100%;
        padding: 50px 0;
        width: 100%;

        background: $color-header-bg;
        backdrop-filter: blur(108px);

        @include media-breakpoint-down(md) {
            padding: 15px 0;
        }
    }

    .logo {
        @include media-breakpoint-down(md) {
            margin: 0 auto 30px;
            text-align: center;
            display: block;
        }
    }

    &__copy {
        font-family: $font-family-bold;
        padding: 15px;
        color: $color-white;
        text-align: center;
        font-size: 14px;
        line-height: 26px;
    }

	&__nav {
        display: flex;
        justify-content: flex-end;

        @include media-breakpoint-down(sm) {
            justify-content: center;
        }

        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }

        nav {
            padding: 0 30px;
            min-width: 150px;

            @include media-breakpoint-down(sm) {
                margin: 0 auto 30px;
                padding: 0;
                min-width: auto;
                text-align: center;
            }

            &:last-of-type {
                padding-right: 0;
            }

            h3 {
                font-family: $font-family-bold;
                font-weight: 600;

                font-size: 14px;
                line-height: 26px;
                display: block;
                margin-bottom: 10px;

                background: linear-gradient(264.08deg, #967BFF 28.33%, #6E52DF 94.58%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
            }

            a {
                font-family: $font-family-bold;
                font-weight: 400;

                font-size: 13px;
                line-height: 20px;
                letter-spacing: 0.01em;

                color: rgba(255, 255, 255, 0.76);
                display: block;
                margin-bottom: 5px;
            }
        }
    }
}