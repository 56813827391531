.s-about {
    padding: 50px 0 150px;

    @include media-breakpoint-down(md) {
        margin-bottom: 0;
    }

    &__subhead {
        font-family: $font-family-bold;
        font-weight: 600;

        font-size: 18px;
        line-height: 26px;
        margin-bottom: 30px;
        display: block;
        margin-bottom: 10px;

        background: linear-gradient(90.05deg, #4FDBEA -3.05%, #4666CA 32.08%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        text-align: center;
    }

    &__headline {
        font-family: $font-family-headings;
        font-size: 38px;
        line-height: 45px;
        margin-bottom: 15px;
        color: $color-white;
        text-align: center;

        @include media-breakpoint-down(sm) {
            font-size: 22px;
            line-height: 32px;
        }

        span {
            color: $color-primary;
        }
    }

    &__list {
        margin: 25px 0 0;
        list-style: none;

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 100px;

        @include media-breakpoint-down(sm) {
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 50px;
        }

        &-item {
            text-align: center;

            div {
                display: inline-flex;
                margin-bottom: 35px;
            }

            img {
                max-width: 200px;
                padding: 0 20px;
                border-radius: 50%;

                @include media-breakpoint-down(sm) {
                    max-width: 150px;
                }
            }

            h3 {
                font-family: $font-family-headings;
                font-size: 18px;
                line-height: 26px;
                color: $color-white;
                display: block;
                text-align: center;
            }

            p {
                font-family: $font-family-medium;
                font-size: 14px;
                line-height: 20px;
                color: #A4B1C1;

                margin-top: 15px;
                text-align: center;

                @include media-breakpoint-down(sm) {
                    text-align: center;
                }
            }
        }
    }
}