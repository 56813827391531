/**
 * Document defaults (html, body)
 */

 * {
    outline: 0;
    border: 0;
    box-sizing: border-box;
}

html,
body {
    width: 100%;
    margin: 0;
    padding: 0;
}

html {
    font-family: $font-family-regular;
    font-size: 16px;
    color: $color-black;
    line-height: $line-height-base;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    background-color: $color-bg-color;
}

body {
    height: 100%;
    overflow-x: hidden;
}

.container-fluid {
    overflow-x: hidden;
}