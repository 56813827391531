.s-features {
    padding: 75px 0 50px;
    margin: 50px 0;
    background-color: #0D141C;

    @include media-breakpoint-down(sm) {
        padding-bottom: 20px;
    }

    &__subhead {
        font-family: $font-family-bold;
        font-weight: 600;

        font-size: 18px;
        line-height: 26px;
        margin-bottom: 30px;
        display: block;
        margin-bottom: 10px;

        background: linear-gradient(90.05deg, #4FDBEA -3.05%, #4666CA 32.08%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

        @include media-breakpoint-down(sm) {
            text-align: center;
        }

    }

    &__headline {
        font-family: $font-family-headings;
        font-size: 38px;
        line-height: 45px;
        margin-bottom: 15px;
        color: $color-white;
        text-align: left;

        @include media-breakpoint-down(sm) {
            font-size: 22px;
            line-height: 32px;
            text-align: center;
        }

        span {
            color: $color-primary;
        }
    }

    &-img {
        position: relative;
        margin-bottom: 15px;

        background: rgba(27, 43, 61, 0.2);
        border: 1px solid #182D45;
        backdrop-filter: blur(57px);
        /* Note: backdrop-filter has minimal browser support */

        border-radius: 13px;
        display: flex;
        align-items: center;
        justify-content: center;

        width: 60px;
        height: 60px;

        @include media-breakpoint-down(sm) {
            margin: 0 auto 15px;
        }

        img {
            height: 40px;
        }
    }


    &__list {
        margin: 50px 0 100px;
        list-style: none;

        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 40px;


        @include media-breakpoint-down(lg) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include media-breakpoint-down(xs) {
            grid-template-columns: repeat(1, 1fr);
        }

        &-item {
            padding: 50px 30px 50px 0;

            @include media-breakpoint-down(sm) {
                text-align: center;
                padding: 25px 0;
            }
        }


        &-headline,
        &-wrapper {
            display: flex;
            align-content: center;
            flex-flow: column;
        }

        &-headline {
            font-family: $font-family-headings;
            font-size: 18px;
            line-height: 26px;
            color: $color-white;
            display: flex;
        }

        &-copy {
            font-family: $font-family-medium;
            font-size: 14px;
            line-height: 20px;
            color: #A4B1C1;

            margin-top: 15px;
            text-align: justify;

            @include media-breakpoint-down(sm) {
                text-align: center;
            }
        }
    }
}