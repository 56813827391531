.cms {
    padding: 150px 0 100px;

    @include media-breakpoint-down(sm) {
        padding: 75px 0 50px;
    }

    .container {
        padding: 1rem;
        max-width: 960px;
    }

    h1 {
        text-align: center;
        color: $color-white;
        margin-bottom: 50px;
    }

    h2 {
        font-family: $font-family-bold;
        font-size: 22px;
        line-height: 30px;
        color: $color-white;

        margin: 50px 0 25px;
    }

    p,
    span,
    div,
    ul,
    ol {
        font-family: $font-family-regular;
        color: rgba(255, 255, 255, 0.5);
        font-size: 15px;
        line-height: 21px;
        text-align: left;
        text-align: center;

        @include media-breakpoint-down(sm) {
            font-size: 13px;
            line-height: 18px;
        }

        margin-bottom: 20px;
    }

    ul,
    ol {
        margin: 0 0 20px 30px;

        li {
            margin-bottom: 5px;
        }
    }

    a {
        color: $color-primary;
    }
}