.global-headline {
    font-family: $font-family-headings;
    color: $color-global-headline;
    font-size: 80px;
    line-height: 80px;
    margin-bottom: 20px;


    @include media-breakpoint-only(lg) {
        font-size: 60px;
        line-height: 70px;
    }

    @include media-breakpoint-down(md) {
        font-size: 50px;
        line-height: 60px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 40px;
        line-height: 50px;
    }
}

.global-subline {
    font-family: $font-family-medium;
    font-size: 22px;
    line-height: 35px;
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.59);

    @include media-breakpoint-down(md) {
        margin: 0 auto 20px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 18px;
        line-height: 24px;
    }
}