/**
 * Colors
 */

$color-white: #fff;
$color-black: #000;
$color-primary: #4679EC;
$color-bg-color: #030A12;
$color-bg-color-button-dashboard: #4679EC;
$color-header-bg: #0D141C;
$color-typography-primary: #DCE3EB;



$color-bg-color-features: rgba(255, 255, 255, 0.06);
$color-bg-color-features-hover: #D18820;
$color-bg-color-accordion: #0A1119;
$color-global-headline: #fff;
$color-navigation: #fff;
