/**
 * Headings
 */

 h1,
 h2,
 h3,
 h4,
 h5,
 h6,
 .h1,
 .h2,
 .h3,
 .h4,
 .h5,
 .h6 {
   margin: 0;
   font-family: $font-family-headings;
   letter-spacing: $headings-letter-spacing;
   line-height: $headings-line-height;
   color: $headings-color;
   text-transform: $headings-text-transform;
 }

 h1,
 .h1 {
   @include make-responsive(font-size, $h1-font-size);
 }
 h2,
 .h2 {
   @include make-responsive(font-size, $h2-font-size);
 }
 h3,
 .h3 {
   @include make-responsive(font-size, $h3-font-size);
 }