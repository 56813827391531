$color-bg-input:  #f6f9fc;
$color-input-text: #425c9e;

label {
  font-family: $font-family-regular;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  a {
    color: $color-primary;
    font-family: $font-family-medium;
  }
}

/* For Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Webkit browsers like Safari and Chrome */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-field {
  margin-bottom: 15px;

  @include media-breakpoint-down(lg) {
    margin-bottom: 10px;
  }
}

.form-field__wrapper--input-checkbox {
  position: relative;
}

input[type='radio'] {
  -moz-appearance: textfield;
}

// input type text / password
.select {
  font-family: $font-family-medium;
  box-sizing: border-box;
  width: 100%;
  height: 46px;
  padding: 0 35px 0 10px;
  border-radius: 5px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  outline: 0;
  box-shadow: none;
  background-color: $color-white;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Some browsers will not display the caret when using calc, so we put the fallback first */
  background: url('/assets/img/arrow-down.svg') #4C4C4C no-repeat 98.5% !important; /* !important used for overriding all other customisations */
  background: url('/assets/img/arrow-down.svg') #4C4C4C no-repeat calc(100% - 15px) !important; /* Better placement regardless of input width */

  color: rgba(255, 255, 255, 0.68);
  border: none;
  cursor: pointer;

  @media all and (-ms-high-contrast: none) {
    padding-right: 0; /* IE10 */

    *::-ms-backdrop,
    body {
      padding-right: 0; /* IE11 */
    }
  }

  &:disabled {
    opacity: .5;
    color: rgba($color-black, .28);
    border-color: $color-white;
  }
}

.input-unstyled {
  appearance: none;
  box-sizing: border-box;
  outline: 0;
  box-shadow: none;
  background: none !important;
  border: 0;
  border-radius: 0;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.68);
  text-align: center;
}

.input-textarea {
  font-family: $font-family-regular;
  appearance: none; // Removes awkward default styles on some inputs for iOS
  box-sizing: border-box;
  width: 100%;
  outline: 0;
  box-shadow: none;
  background: #4C4C4C;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  color: rgba(255, 255, 255, 0.68);
  border: solid 2px #dde3e7;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  padding: 15px;
  background-image: none !important;
}

.input-text,
.input-textarea {
    border: 1px solid #e2e3e3;
}

.input-text {
  font-family: $font-family-medium;
  font-weight: 500;
  appearance: none; // Removes awkward default styles on some inputs for iOS
  box-sizing: border-box;
  width: 100%;
  outline: 0;
  box-shadow: none;
  background: #4C4C4C;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  color: rgba(255, 255, 255, 0.68);
  font-size: 14px;
  height: 46px;
  padding: 0 15px;
  background-image: none !important;
}

.form-field {
  // general wrapper for form-field elements
  &__wrapper {
    margin-bottom: 20px;

    &.has-error {
      label {
        color: #a94442;
      }

      input {
        color: #a94442;
        border-color: #a94442;
      }
    }

    &--text,
    &--password,
    &--select,
    &--number {
      position: relative;
    }

    &-textarea {
      border: 0;
      position: relative;
    }
  }

  // general label-styling in form-fields
  &__label {
    font-family: $font-family-regular;
    font-size: 13px;
    font-weight: bold;
    display: inline-block;
    margin-bottom: 10px;
    color: #6B7280;

    &.has-error {
      color: #a94442;
    }


    // label behavior in combination with text- and password-inputs
    .form-field__wrapper--number &,
    .form-field__wrapper--text &,
    .form-field__wrapper--password & {
      @include transition($duration: .15s);
      font-size: 14px;
      font-weight: 500;
      background-color: $color-bg-input;
      color: rgba(255, 255, 255, 0.68);
      opacity: .78;
      position: absolute;
      padding: 0 5px;
      left: 10px;
      top: -10px;

      &:hover {
        cursor: text;
      }
    }
  }
}

.option-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  top: 13.33333px;
  right: 0;
  bottom: 0;
  left: 0;
  height: 24px;
  width: 24px;
  transition: all 0.15s ease-out 0s;
  background: #cbd1d8;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin-right: 0.5rem;
  outline: none;
  position: relative;
  z-index: 1000;
}
.option-input:hover {
  background: #9faab7;
}
.option-input:checked {
  background: $color-primary;
}
.option-input:checked::after {
  background: $color-primary;
  content: '';
  display: block;
  position: relative;
  z-index: 100;
}
.option-input.radio {
  border-radius: 5px;
}
.option-input.radio::after {
  border-radius: 5px;
}
