.s-plans {
    padding: 0 0 150px;
    margin-top: -50px;

    @include media-breakpoint-down(md) {
        padding: 0 0 50px;
    }

    &__subhead {
        font-family: $font-family-bold;
        font-weight: 600;

        font-size: 18px;
        line-height: 26px;
        margin-bottom: 30px;
        display: block;
        margin-bottom: 10px;

        background: linear-gradient(90.05deg, #4FDBEA -3.05%, #4666CA 32.08%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        text-align: center;
    }

    &__headline {
        font-family: $font-family-headings;
        font-size: 38px;
        line-height: 45px;
        margin-bottom: 15px;
        color: $color-white;
        text-align: center;

        @include media-breakpoint-down(sm) {
            font-size: 22px;
            line-height: 32px;
        }

        span {
            color: $color-primary;
        }
    }

    &__subline {
        font-family: $font-family-medium;
        font-size: 20px;
        line-height: 30px;
        color: #A4B1C1;

        margin: 20px auto;
        text-align: center;

        display: block;
        max-width: 600px;

        @include media-breakpoint-down(sm) {
            font-size: 18px;
            line-height: 22px;
        }
    }

    .pricing-table {
        width: 100%;

        .pricing-plan {
            backdrop-filter: blur(57px);
            /* Note: backdrop-filter has minimal browser support */

            border-radius: 24px;
            padding: 30px 40px;

            &-grey {
                @include transition;
                background: rgba(27, 43, 61, 0.2);
                border: 1px solid #182D45;
            }

            &-colored {
                background: linear-gradient(296.27deg, #3B5DC9 7.74%, #0D1FB1 96.81%);
                border: 1px solid #182D45;
            }

            @include media-breakpoint-down(md) {
                max-width: 450px;
            }

            &-wrap {
                @include media-breakpoint-down(md) {
                    &:not(:last-child) {
                        margin-bottom: 50px;
                    }
                }
            }
        }

        .pricing-head {
            h4 {
                font-family: $font-family-regular;
                font-weight: 700;
                font-size: 30px;
                line-height: 40px;
                color: $color-white;

                @include media-breakpoint-down(md) {
                    font-size: 20px;
                    line-height: 30px;
                }

                span {
                    display: inline;
                    background: linear-gradient(96.88deg, #51B3CE -47.35%, #497FEE 126.12%);
                    border-radius: 50px;
                    color: #fff;
                    padding: 5px 15px;
                    font-size: 13px;

                    position: relative;
                    top: -5px;
                    left: 5px;

                    @include media-breakpoint-only(lg) {
                        padding: 5px 10px;
                    }
                }
            }

            p {
                font-family: $font-family-regular;
                font-weight: 400;
                font-size: 18px;
                line-height: 26px;
                letter-spacing: 0.015em;
                color: #A4B1C1;

                margin: 15px 0;
            }
        }

        .pricing-amount {
            display: flex;
            align-items: center;
            color: rgba(255, 255, 255, 0.5);
            font-family: $font-family-regular;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;

            p {
                color: #FFFFFF;
                font-weight: 600;
                font-size: 52px;
                line-height: 63px;
                padding: 0 10px;
            }

            span {
                font-family: $font-family-regular;
                font-weight: 600;
                font-size: 18px;
                line-height: 26px;
                letter-spacing: 0.015em;
                color: $color-white;

                position: relative;
                top: -10px;
            }
        }

        .btn {
            display: block;
            margin: 20px 0 25px;
            width: 100%;
        }

        .leading-loose {
            list-style: none;

            &__more {
                display: none;
            }

            li {
                font-family: $font-family-regular;
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
                letter-spacing: 0.015em;
                color: #A4B1C1;

                strong {
                    color: $color-white;
                }

                p,
                span {
                    padding: 15px 0;
                    display: block;
                }

                span {
                    cursor: pointer;
                }

                &:not(:last-child) {
                    &::after {
                        content: '';
                        width: 100%;
                        height: 1px;
                        display: block;
                        background: rgba(255, 255, 255, 0.06);
                    }
                }
            }
        }
    }
}