.slider {
    position: relative;
    margin: 50px auto 0;
    width: 100%;
}

.swiper {
    width: 100%;
    height: auto;

    background: rgba(27, 43, 61, 0.2);
    border: 1px solid #182D45;
    backdrop-filter: blur(57px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 24px;
    max-width: 1025px;
    margin: 0 auto;
}

.swiper-slide {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;

    @include media-breakpoint-down(xl) {
        padding: 55px;
    }
}

.swiper-slide img {
    display: block;
    width: auto;
}

.swiper-pagination {
    position: relative !important;
    top: 25px !important;
}

.swiper-button-next,
.swiper-button-prev {
    z-index: 999 !important;

    svg {
        width: 250px;
    }

    &::after {
        display: none !important;
    }
}

.swiper-button-next  {
    right: 50px !important;

    @include media-breakpoint-down(xl) {
        right: 10px !important;
    }
}

.swiper-button-prev  {
    left: 50px !important;

    @include media-breakpoint-down(xl) {
        left: 10px !important;
    }
}

.swiper-pagination-bullet {
    background: $color-white !important;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
  }

  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.swiper-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
    align-items: center;

    @include media-breakpoint-down(sm) {
       display: block;
    }


    h3 {
        font-family: $font-family-headings;
        font-size: 30px;
        line-height: 38px;
        margin-bottom: 15px;
        color: $color-white;

        @include media-breakpoint-down(sm) {
            font-size: 22px;
            line-height: 32px;

            margin-top: 25px;
        }

        span {
            color: $color-primary;
        }
    }

    p {
        font-family: $font-family-medium;
        font-size: 16px;
        line-height: 20px;
        color: #A4B1C1;

        display: block;
        max-width: 600px;
    }
}