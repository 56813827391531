.s-opener {
    margin: 100px 0;
    display: block;
    height: 100%;

    @include media-breakpoint-up(lg) {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;

        margin: 0;
    }

    @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
        margin-top: -20px;
    }

    &__imgbox,
    .container {
        position: relative;
    }

    .container {
        z-index: 99;
    }

    &__imgbox {
        position: relative;
        width: 100% !important;
        z-index: -1;
        overflow: hidden;

        @include media-breakpoint-up(lg) {
            position: absolute;
        }

        .animated-stage {
            width: 100% !important;
            @include media-breakpoint-up(lg) {
                max-width: 50%;
                float: right;
            }

            @include media-breakpoint-up(lg) {
                position: relative;
            }

            img {
                @include media-breakpoint-up(xxl) {
                    float: right;
                }

                @include media-breakpoint-down(md) {
                    width: 100%;
                    float: right;

                    margin: 50px 0 0;
                }
            }
        }
    }

    small {
        display: flex;
        align-items: center;
        color: rgba(255, 255, 255, 0.55);
        margin-top: 15px;

        @include media-breakpoint-down(lg) {
            justify-content: center;
        }

        svg {
            margin-right: 5px;
        }
    }

    &__btns {
        @include media-breakpoint-down(lg) {
            text-align: center;
        }
    }

    &__name {
        font-family: $font-family-bold;
        font-weight: 600;

        font-size: 18px;
        line-height: 26px;
        margin-bottom: 30px;
        display: block;
        margin-bottom: 10px;

        background: linear-gradient(90.05deg, #4FDBEA -3.05%, #4666CA 32.08%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;


        @include media-breakpoint-down(lg) {
            text-align: center;
            font-size: 15px;
            line-height: 26px;
        }
    }

    img {
        @include media-breakpoint-down(lg) {
            margin: 0 auto;
        }
    }

    &__headline {
        font-family: $font-family-headings;
        font-weight: 700;
        font-size: 60px;
        line-height: 81px;
        margin-bottom: 20px;

        color: $color-white;

        display: inline-block;

        span {
            background: -webkit-linear-gradient(left, #EBDCFF, #7200D2);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        @include media-breakpoint-down(lg) {
            text-align: center;
            font-size: 50px;
            line-height: 60px;
            display: block;
        }
    }

    &__subline {
        font-family: $font-family-medium;
        font-weight: 400;
        margin-bottom: 30px;
        font-size: 22px;
        line-height: 32px;
        color: #A4B1C1;

        max-width: 680px;

        @include media-breakpoint-down(lg) {
        margin: 0 auto 50px;
        font-size: 15px;
        line-height: 26px;
        }

        @include media-breakpoint-down(sm) {
            font-size: 14px;
            line-height: 24px;
        }
    }

    .btn-sold-out,
    .btn-purchase,
    .btn-primary {
        margin-right: 15px;

        @include media-breakpoint-down(xs) {
            margin-bottom: 10px;
        }
    }

    .btn-primary{
        @include media-breakpoint-down(xs) {
            margin-right: 0;
            margin-bottom: 10px;
        }
    }
}