.nav {
	@include media-breakpoint-up(lg) {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		height: 100%;
		width: 100%;
		position: relative;
	}

	@include media-breakpoint-down(md) {
		padding-left: 15px;
		padding-right: 15px;
	}

	&-list {
		display: flex;
		flex-direction: row;
		margin: 0;

		&--dropdown {
            align-items: center;

			@include media-breakpoint-up(lg) {
				align-items: center;
				height: 48px;
			}

			@include media-breakpoint-down(md) {
				@include transition;
				opacity: 0;
				visibility: hidden;
				z-index: -1;

				align-items: baseline;
				display: flex !important;
				flex-flow: column;

				width: 100%;
				height: calc(100vh - var(--vh-offset, 0px));

			    position: fixed;
				left: 0;
				right: 0;

				background-color: $color-header-bg;
				z-index: 999;

				padding: 50px 0 0 0;
				margin: 100px auto 0px;

			    .nav-list__item {
					padding: 0 20px;
					margin-bottom: 40px !important;

			    	a {
						display: block;

						@include media-breakpoint-down(md) {
							display: inline-block;
						}
					}

					&-btn {
						margin-bottom: 45px !important;
					}

					&-small {
						margin-bottom: 5px !important;
					}
			    }
			}
		}

		&__item {
			&--dash {
				margin-right: 0;
			}

			&:not(:last-of-type) {
				margin-right: 65px;

				@include media-breakpoint-down(md) {
					margin: 0;
				}
			}

			&:nth-child(6n) {
				margin-right: 0;
			}

			&-link {
				@include transition;

				font-family: $font-family-bold;
				font-weight: 600;
				font-size: 16px;
				color: $color-typography-primary;

				position: relative;

				&-small {
					font-size: 13px !important;
					line-height: 16px !important;
					margin-top: 15px;
				}

				@include media-breakpoint-only(lg) {
					font-size: 15px;
				}


				@include media-breakpoint-down(md) {
					font-size: 24px;
					line-height: 28px;
				}

				&::after {
					@include transition;

					content: '';
					display: block;
					border-bottom: 3px solid $color-primary;
					width: 0;
					position: absolute;
					left: 0;
				}

				&:hover {
					color: $color-primary;
					text-decoration: none !important;

					&::after {
						width: 100%;
					}
				}

			}

			.btn {
				@include media-breakpoint-down(md) {
					width: auto;
					box-sizing: border-box;
					font-size: 24px;
					line-height: 28px;
				}
			}

			&-burger {
				position: absolute;
				top: 40px;
				right: 20px;
				display: none;

				@include media-breakpoint-down(md) {
					display: block;
				}

				input {
					display: none;
				}

				&:hover {
					&::after {
						display: none;
					}
				}
			}
		}
	}
}