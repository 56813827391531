.accordion {
    max-width: 920px;
    width: 100%;
    margin: 40px auto 0;
    list-style: none;

    @include media-breakpoint-down(md) {
       padding: 0;
    }

    li {
        background: transparent;
        border-radius: 11px;
        position: relative;
        margin-bottom: 20px;

        &.active {
            background: $color-bg-color-accordion;
        }

        p {
            font-family: $font-family-regular;
            font-size: 14px;
            line-height: 22px;

            display: none;
            padding: 8px 20px 30px;
            color: rgba(255, 255, 255, 0.59);

            a {
                color: $color-white;
                text-decoration: none;
                font-weight: bold;
            }
        }

        span {
            font-family: $font-family-regular;
            font-weight: 700;
            font-size: 17px;
            line-height: 28px;
            color: #fff;
            display: inline-block;
            width: 100%;
            display: block;
            cursor: pointer;
            user-select: none;
            padding: 12px 40px 12px 20px;

            @include media-breakpoint-down(md) {
                font-size: 14px;
                line-height: 26px;
            }

            &.active {
                color: $color-white;
            }

            &::after {
                @include transition;
                width: 8px;
                height: 8px;
                border-right: 2px solid $color-white;
                border-bottom: 2px solid $color-white;
                position: absolute;
                right: 20px;
                content: " ";
                top: 17px;
                transform: rotate(45deg);
            }

            &.active::after {
                @include transition;
                transform: rotate(-135deg);
                border-right: 2px solid $color-white;
                border-bottom: 2px solid $color-white;
            }

            &:hover {
                text-decoration: none;
            }
        }
    }
}